import React from "react"
import { Fade } from "react-awesome-reveal"

const Banner = ({ title, apartment, stage }) => {
  return (
    <section className={`banner ${apartment ? "banner--apartment" : ""}`}>
      <div className="container">
        <Fade delay={150} triggerOnce>
          <div className="row">
            <div className="col-12 col-xxl-10 offset-xxl-1">
              {/* {stage === 1 ? <h2 className="banner__title-info">Etap I - zakończenie prac IV kwartał 2021</h2>  : stage === 2 ? <h2 className="banner__title-info">  Etap II - zakończenie IV kwartał 2023</h2> : ""} */}
              {apartment && (
                <p className="h3 font-regular banner__uptitle">Mieszkanie</p>
              )}
              <h1 className="banner__title">{title}</h1>
            </div>
          </div>
        </Fade>
      </div>
      <span className="banner__tree"></span>
    </section>
  )
}

export default Banner
